import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box, Link, Container, Typography, Stack } from '@mui/material';
// components
// import Logo from '../../components/Logo';
// import Image from '../../components/Image';
//
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />


        <MainFooter />
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            {/* <Logo sx={{ mb: 1, mx: 'auto' }} /> */}
            {/* <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/logo/logo_full.svg"
              sx={{ alignItems: 'center', maxWidth: 120 }}
            /> */}

            <Typography variant="caption" component="p">
              <br /> By &nbsp;
              <Link href="https://www.freehali.com/">freehali.com</Link>
            </Typography>
          </Container>
        </Box>
    
    </Stack>
  );
}
