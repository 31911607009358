import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import usePayForm from '../hooks/usePayForm';
// routes
import { PATH_PAGE } from '../routes/paths';

// ----------------------------------------------------------------------

PaymentGuard.propTypes = {
  children: PropTypes.node,
};

export default function PaymentGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { payment } = usePayForm();
  const { pathname } = useLocation();

  if (payment.price === -1) {
    return <Navigate to={PATH_PAGE.pricing} />;
  }
  if (isAuthenticated && pathname !== '/payment-register' && payment.price !== 0) {
    return <Navigate to={PATH_PAGE.payment} />;
  }
  // if (isAuthenticated && pathname === '/payment-register' && payment.price === 0) {
  //   return <Navigate to={PATH_DASHBOARD.root} />;
  // }

  return <>{children}</>;
}
