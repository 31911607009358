import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
// import { getFirestore, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import {
  getFirestore,
  doc,
  getDocs,
  getDoc,
  collection,
  addDoc,
  where,
  query,
  onSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
  enableIndexedDbPersistence
} from 'firebase/firestore';
// times utils
import { formatISO } from 'date-fns';
// utils
import { v4 as uuidv4 } from 'uuid';
//
import { FIREBASE_API } from '../config';
import axios from '../utils/axios-dev';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['ramzi@freehali.co'];

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

  enableIndexedDbPersistence(DB)
    .then(() => console.log("Enabled offline persistence"))
    .catch((error) => {
      if (error.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (error.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
  notifications: [],
};

const findIndexById = (id, array) => {
  let index = -1;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      index = i;
      break;
    }
  }
  return index;
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, token, notifications } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token,
      notifications,
    };
  }
  if (action.type === 'ADD') {
    const { notification } = action.payload;
    return {
      ...state,
      notifications: [...state.notifications, notification],
    };
  }
  if (action.type === 'UPDATE') {
    const { notification } = action.payload;
    const _notifications = state.notifications;
    const index = findIndexById(notification.id, state.notifications);
    _notifications[index] = notification;
    return {
      ...state,
      notifications: [..._notifications],
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerByLink: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  sendNotificationToAdmins: () => Promise.resolve(),
  sendNotificationToUser: () => Promise.resolve(),
  setNotificationsToRead: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          // console.log(user.accessToken);
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            setProfile(docSnap.data());
          }

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user, token: user.accessToken, notifications: [] },
          });

          // const res = await axios.get(`/notifications/${user.uid}`);
          // if (res.status === 200) {
          //   setNotifications(res.data);
          // }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null, token: null, notifications: [] },
          });
        }
      }),
    [dispatch]
  );

  useEffect(() => {
    const unsubscribe = state.isAuthenticated
      ? onSnapshot(doc(DB, 'users', state.user.uid), (doc) => {
          if (doc.exists()) {
            setProfile(doc.data());
          }
        })
      : () => {};

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
  }, [state]);

  useEffect(() => {
    const unsubscribe = state.isAuthenticated
      ? onSnapshot(collection(DB, `users/${state.user.uid}`, 'notifications'), (docs) => {
          docs.docChanges().forEach((change) => {
            if (change.type === 'added') {
              dispatch({
                type: 'ADD',
                payload: { notification: change.doc.data() },
              });
            }
            if (change.type === 'modified') {
              dispatch({
                type: 'UPDATE',
                payload: { notification: change.doc.data() },
              });
            }
            if (change.type === 'removed') {
              console.log('notification removed', change.doc.data());
            }
          });
        })
      : () => {};

    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isAuthenticated]);

  const login = async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  };

   const register = (email, password, firstName, lastName, data) =>
     createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
       const userRef = doc(collection(DB, 'users'), res.user?.uid);
       await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        phone: data?.phone,
        role: 'client',
      });
     });

     const registerByLink = (data, newPassword, userId) =>
      createUserWithEmailAndPassword(AUTH, data.email, newPassword).then(async (res) => {
        const userData = doc(collection(DB, 'users'), res.user?.uid);
        await setDoc(userData, {
          uid: res.user?.uid,
          email: data?.email,
          displayName: data?.displayName,
          phone: data?.phone,
          role: data?.role
        });
        const docRef = doc(DB, "users", userId);

        deleteDoc(docRef)
        .then(() => {
          console.log("Entire Document has been deleted successfully.")
        })
        .catch(error => {
            console.log(error);
        })
      
     });

//     const register = async (email, password, firstName, lastName, data) => {
//       try {
//         const res = await axios.post('/client', {
//           email,
//           password,
//           displayName: `${firstName} ${lastName}`,
//           data,
//         });
//         if (res) {
//           // console.log('uid : ', res?.uid || null);
//           login(email, password);
//         }
//       } catch (error) {
//         console.log(error);
//         throw new Error(error.message);
//       }
//     };

  const logout = () => {
    setProfile(null);
    signOut(AUTH);
  };

  const sendNotificationToAdmins = async (data) => {
    try {
      const q = query(collection(DB, 'users'), where('role', '==', 'admin'));
      const docSnap = await getDocs(q);
      docSnap.forEach(async (user) => {
        await addDoc(collection(DB, `users/${user.id}/notifications`), data);
      });
    } catch (error) {
      console.log('send notifications to admin error', error);
    }
  };

  const sendNotificationToUser = async (uid, data) => {
    try {
      const currentDate = formatISO(new Date());
      const uuid = uuidv4();
      await setDoc(doc(DB, `users/${uid}/notifications`, uuid), {
        ...data,
        id: uuid,
        date: currentDate,
        isUnRead: true,
      });
    } catch (error) {
      console.log('send notifications to user error', error);
    }
  };

  const setNotificationsToRead = async (uid, nid) => {
    try {
      await updateDoc(doc(DB, `users/${uid}/notifications`, nid), {
        isUnRead: false,
      });
    } catch (error) {
      console.log('update notifications status error', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: ADMIN_EMAILS.includes(state?.user?.email) ? 'admin' : profile?.role || '',
          phone: profile?.phone || state?.user?.phone || '',
          wilaya: profile?.wilaya || '',
          commune: profile?.commune || '',
          magasin: profile?.magasin || '',
          status: profile?.status || '',
          information: profile?.information || '',
          count: profile?.count || 0,
          currentCount: profile?.currentCount || 0,
          dataLimit: profile?.dataLimit || 0,
          notifications: state.notifications,
        },
        login,
        register,
        registerByLink,
        logout,
        sendNotificationToAdmins,
        sendNotificationToUser,
        setNotificationsToRead,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider, DB, AUTH };
