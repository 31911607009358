import axios from 'axios';
// config
import { HOST_API_DEV } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API_DEV,
});

// const requestHandler = (request) => {
//   request.headers.Authorization =
//     'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTIzNDU2Nzg5IiwibmFtZSI6IlNhbXBsZSIsImlhdCI6MTUxNjIzODIzfQ.ZEBwz4pWYGqgFJc6DIi7HdTN0z5Pfs4Lcv4ZNwMr1rs';

//   return request;
// };

axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => Promise.reject(error || 'Something went wrong')
);

axiosInstance.interceptors.response.use(
  (response) => {
    if ([200, 201, 202, 203, 204].includes(response.status)) {
      return response.data;
    }
    return response;
  },
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
