import {
  getFirestore,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  collection,
  addDoc,
  where,
  query,
  onSnapshot,
  setDoc,
  updateDoc,
  arrayUnion,
  increment,
} from 'firebase/firestore';
import { getStorage, ref, deleteObject } from "firebase/storage";
import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios-dev';
//
import { dispatch } from '../store';
import { DB } from '../../contexts/FirebaseContext';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [],
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    categorie: 'Tout', 
    categorieParent: '',
    colors: [],
    priceRange: [0,200],
    rating: '',
    conditionpaiment: [],
    spec: [],
    status: [],
    papiers: [],
    wilaya: '',
    commune: '',
    superficieRange: [0,550],
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.categorie = action.payload.categorie;
      state.filters.categorieParent = action.payload.categorieParent;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.superficieRange = action.payload.superficieRange;
      state.filters.rating = action.payload.rating;
      state.filters.conditionpaiment = action.payload.conditionpaiment;
      state.filters.spec = action.payload.spec;
      state.filters.status = action.payload.status;
      state.filters.papiers = action.payload.papiers;
      state.filters.wilaya = action.payload.wilaya;
      state.filters.commune = action.payload.commune;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.id === product.id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'id');
    },

    deleteCart(state, action) {
      const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  sortByProducts,
  filterProducts,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(limit, criterion) { 
  return async () => {
    dispatch(slice.actions.startLoading());
    const q = query(collection(DB, 'posts'));
    try {
      const snap = await getDocs(q);
      if(snap){
        const response = snap.docs.map(k => ({...k.data()}));
        console.log('yes baby', response)
        dispatch(slice.actions.getProductsSuccess(response));
      }
      // const response = await axios.get('/products/first', { params: { limit, criterion } });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const q = query(collection(DB, 'posts'), where('slug', '==', name));
    try {
      const snap = await getDocs(q);
      if(snap){
       const response = snap.docs.map(k => ({id: k.id, ...k.data()}));
       console.log('tcha', response)
       dispatch(slice.actions.getProductSuccess(response[0]));
      } 
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProduct(postId) {
  return async () => {
    dispatch(slice.actions.startLoading());

    // const storage = getStorage();

    // Create a reference to the file to delete
    // const desertRef = ref(storage, 'images/desert.jpg');
    
    // Delete the file
    // deleteObject(desertRef).then(() => {
      // File deleted successfully
    // }).catch((error) => {
      // Uh-oh, an error occurred!
    // });


    const docRef = doc(DB, "posts", postId); 

    deleteDoc(docRef)
    .then(() => {
        console.log("Entire Document has been deleted successfully.");
    })
    .catch(error => {
        console.log(error);
        dispatch(slice.actions.hasError(error));
    })

  };
}
