import { format, getTime, formatDistanceToNow, parseISO } from 'date-fns';
import fr from 'date-fns/locale/fr';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fiso(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fromISO(date) {
  return format(parseISO(date), 'dd MMM yyyy HH:mm', { locale: fr });
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
