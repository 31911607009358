import { BehaviorSubject } from 'rxjs';
import { useEffect, useState } from 'react';

const initialData = {
  id: '',
  subscription: '',
  price: -1,
  voucherUrl: '',
};

let formData = { ...initialData };

const formData$ = new BehaviorSubject(formData);

const updateForm = (data) => {
  formData = { ...formData, ...data };
  formData$.next(formData);
};

const resetForm = () => {
  formData = { ...initialData };

  formData$.next(formData);
};

const usePayForm = () => {
  const [payment, setPayment] = useState({ ...formData });

  useEffect(() => {
    const sub = formData$.subscribe((formData) => setPayment(formData));

    return () => {
      sub.unsubscribe();
    };
  }, []);

  return { payment, updateForm, resetForm };
};

export default usePayForm;
