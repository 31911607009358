// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
// import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  house: getIcon('ic_banking'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'tableau de bord',
    items: [
      { title: 'Accueil', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Mon compte', path: PATH_DASHBOARD.user.account, icon: ICONS.user },
      // { title: 'Mon abonnement', path: PATH_DASHBOARD.abonnement.list, icon: ICONS.kanban },
      { title: 'Notifications', path: PATH_DASHBOARD.notifications.list, icon: ICONS.mail },
      { title: 'Calendrier', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'gestion',
    items: [
      // USER
      {
        title: 'Utilisateurs',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'liste', path: PATH_DASHBOARD.user.list },
          { title: 'ajouter', path: PATH_DASHBOARD.user.new },
          // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        ],
      },

       // E-COMMERCE
       {
         title: 'Annonces',
         path: PATH_DASHBOARD.eCommerce.root,
         icon: ICONS.house,
         children: [
           { title: 'Liste', path: PATH_DASHBOARD.eCommerce.root},
           { title: 'Créer', path: PATH_DASHBOARD.eCommerce.new },
           // { title: 'Modifier', path: PATH_DASHBOARD.eCommerce.demoEdit },
         ],
      },

      // INVOICE
      // {
      //  title: 'factures',
      //  path: PATH_DASHBOARD.invoice.root,
      //  icon: ICONS.invoice,
      //  children: [
      //    { title: 'liste des factures', path: PATH_DASHBOARD.invoice.list },
          // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
          // { title: 'create', path: PATH_DASHBOARD.invoice.new },
          // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //  ],
      // },

      // eCommerce
      // {
      //   title: 'eCommerce',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'Liste', path: PATH_DASHBOARD.products.list },
      //     { title: 'Editer', path: PATH_DASHBOARD.products.edit },
      //     // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     // { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // Produit not found
      // {
      //  title: 'gestion des produits',
      //  path: PATH_DASHBOARD.invoice.root,
      //  icon: ICONS.cart,
      //  children: [
      //    { title: 'Liste', path: PATH_DASHBOARD.products.list },
          // { title: 'Editer', path: PATH_PAGE.comingSoon },
          // { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
          // { title: 'create', path: PATH_DASHBOARD.invoice.new },
          // { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // Plans
      {
        title: 'Catégories',
        path: PATH_DASHBOARD.plan.root,
        icon: ICONS.kanban,
        children: [
          { title: 'Ajouter', path: PATH_DASHBOARD.plan.new },
          { title: 'Liste', path: PATH_DASHBOARD.plan.list },
        ],
      },

      // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
];

export default navConfig;
